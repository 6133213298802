@import "~shared/PageHeader/styles.module.scss";

.education {
  padding-top: $header-height + 1rem;
  min-height: calc(100vh - 16rem);
  font-size: 5rem;
  font-weight: 600;

  .institution {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;

    &:first-of-type {
      margin-top: 2rem;
      flex-direction: column-reverse;
    }

    &:not(:last-child) {
      margin-bottom: 10rem;
    }

    @media (min-width: 700px) {
      &,
      &:first-of-type {
        flex-direction: row;
        gap: 10rem;
      }

      &:not(:last-child) {
        margin-bottom: 15rem;
      }

      .text .description {
        margin-top: 1rem;
      }
    }

    .text {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 3rem;
      }

      .course {
        font-size: 2rem;
      }

      .description {
        margin-top: 2rem;
        font-size: 2rem;
      }
    }

    .cefetLogo {
      width: 30rem;
    }
  }
}
