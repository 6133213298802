@import "~shared/PageHeader/styles.module.scss";

$margin-top: 2rem;

.presentation {
  margin-top: $margin-top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 3rem;
  min-height: calc(100vh - #{$header-height} - #{$margin-top});

  @media (min-width: 700px) {
    font-size: 4rem;
  }

  .introduction {
    text-align: center;
    font-weight: 600;
    border: 3px dashed#00493e;
    padding: 2rem 3rem;
  }
  
  .myPhoto {
    margin-top: 4rem;
    width: 25rem;
    border-radius: 50%;
    user-select: none;
    border: 3px solid #00493e;
  }
  
  .icons {
    display: flex;
    gap: 3rem;
    margin-top: 5rem;
    flex-direction: column;

    @media (min-width: 700px) {
      flex-direction: row;
      margin-top: 24rem;
      gap: 6rem;
    }
  
    .sectionLink {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: transform 0.3s;
  
      &:hover {
        transform: scale(1.2);
      }
  
      .icon,
      .wrenchIcon {
        border-radius: 50%;
        padding: 3rem;
        border: 3px solid #00493e;
        font-size: 9rem;
        width: 16rem;
        height: 16rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .wrenchIcon {
        position: absolute;
        background: rgba(0,0,0,0.5);
        color: white;
      }
  
      .text {
        font-weight: 600;
        margin-top: 1rem;
      }
    }
  }
}
