$header-height: 10rem;

.header {
  width: 100%;
  height: $header-height;
  padding: 2rem 4rem;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: transparent;
  transition: all 0.3s;
  z-index: 1;

  &.opaque {
    background: #ffb6c1;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  }

  .pageIcon {
    font-family: "Alata";
    text-decoration: none;
    font-size: 3rem;
    border: 3px solid #00493e;
    padding: 4px;
    border-radius: 4px;
    font-weight: bold;
  }

  .rightContent {
    font-size: 3rem;
  }

  .leftContent,
  .rightContent {
    display: flex;
    align-items: center;
  }
}
