html {
  font-size: 8px;
  scroll-behavior: smooth;
}

* {
  font-family: "Poiret One";
  box-sizing: border-box;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
}

body {
  background: linear-gradient(-45deg, #f5d59a,  #ffb6c1,#ffb6c1, #f5d59a);
  background-size: 800% 800%;
  animation: gradient 20s ease infinite;
  background-color: #000;
  color:#00493e;
  padding: 0;

  #root {
    height: 100%;
  }
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

body,
html {
  margin: 0;
  height: 100%;
}
