.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: static;
  height: 10rem;
  padding: 2rem 0;
  margin-top: 4rem;

  & > a:first-child {
    margin-right: 3rem;
  }
}
